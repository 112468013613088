// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,

  API: 'https://api.innovaschools.edu.co',
  API2: 'https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com',
  API3: 'https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com',

  API_ENDPOINTS: 'https://api.innovaschools.co',
  API_ENDPOINTS_TEST: 'https://api-test.innovaschools.co',

  APP: '',
  COOKIE: 'innova-school-canary-session',
  PSE_TICKET: 'innova-school-pse-ticket-canary',
  PSE_TRANSACTION: 'innova-school-pse-transaction-canary',
  ADMIN_COOKIE: 'innova-school-canary-session-admin',
  SELECTED_CHILD_COOKIE: 'innova-school-child',
  SELECTED_PAYMENT_COOKIE: 'innova-school-pension-gateway',
  SELECTED_PAYMENT_COOKIE_ANY: 'innova-school-pension-gateway',
  SELECTED_SCHOOL: 'innova-school-external',
  SCHOOL_EXTERNA: '56a80304-f5a2-4c77-96bf-83aa696b69e5',
  GRADOS: [
    'ETAPA 1 - Prejardín',
    'ETAPA 1 - Jardín',
    'ETAPA 1 - Transición',
    'ETAPA 1 - Primero',
    'ETAPA 2 -Segundo',
    'ETAPA 2 - Tercero',
    'ETAPA 2 - Cuarto',
    'ETAPA 2 - Quinto',
    'ETAPA 3 - Sexto',
    'ETAPA 3 - Séptimo',
    'ETAPA 3 - Octavo',
    'ETAPA 4 -Noveno',
    'ETAPA 4 -Decimo',
    'ETAPA 4 -Once',
  ],
  DOCUSIGN_BASE_PATH: 'https://account-d.docusign.com/oauth/auth',
  DOCUSIGN_INTEGRATION_KEY: 'c7a74700-af19-421d-a794-fa5ca9c63e6d',
  DOCUSIGN_REDIRECT_URL: 'http://localhost:4200/i/dashboard',
  DOCUSIGN_SCOPES: 'signature',
  EPAYCO_PENSION: 'https://api.innovaschools.co/confirmacionpago/epayco',
  AMBIENTE: 'https://admisiones.innovaschools.edu.co',

  // ----------------------- Cogito ------------------------------
  clientId: '6fhtkfijsht0ptpusgj6bohmfn',

  cognito_endpoint: 'https://innova-family.auth.us-east-1.amazoncognito.com/',
  cognito_oauth2: 'oauth2/token',
  cognito_userinfo: 'oauth2/userInfo',
  cognito_authorize: 'oauth2/authorize',
  cognito_logout: 'logout',
  //app_endpoint: 'http://localhost:4200/admin/login/verify',
  //app_login: 'http://localhost:4200/admin/login',
  app_endpoint: 'https://ap-223.dev.innovaschools.edu.co/admin/login/verify',
  app_login: 'https://ap-223.dev.innovaschools.edu.co/admin/login',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
